import React, { useState, useCallback, useEffect, useRef } from "react";
import { Colors, Routes } from "../../../configs";
import { useHistory } from "react-router-dom";
import useModalWithKeyboard from "../../../hooks/useModalWithKeyboard";
import {
  deleteLogoImage,
  deleteSignatureImage,
  uploadLogoImage,
  uploadMyProfileImage,
} from "../../../modules/ApiHelpers";
import { Image } from "react-native";

import APIService from "../../../modules/apiService";
import AuthService from "../../../modules/authService";
import Helpers from "../../../modules/helpers";
import {
  EVENT_LOAD_SIGNATURE,
  EVENT_RELOAD_PRESCRIPTION_PROFILES,
  EVENT_SHOW_MODAL_INFO,
} from "../../../constants/Events";
import locales_es from "../../../locales/es";
import { validateEmptyFields } from "../../../utils/validation/forms";
import { EventRegister } from "../../../modules/EventRegister";
import { showModalInfo } from "../../../modules/ViewHelpers";
import AddSignature from "../../AddSignature";
import { useTheme } from "../../../configs/Theme";
import TranslationService from "../../../locales";
import { userTemporalDataPostRegister } from "../../../models/constants";
import Text from "../../../components/Text";
import PrescriptionService from "../../../modules/PrescriptionService";
import { SOURCE_ICON } from "../../../images";

const CUSTOM_STAMP_MAX_WORD_LENGTH = 25;

const useOtherInformationPage = () => {
  const history = useHistory();
  const params: any = history.location.state;
  let { userData, stepTitle, firstProfile }: any =
    params && params ? params : null;

  let medicalLicenseId =
    params && params.medicalLicenseId ? params.medicalLicenseId : null;

  const prescriptionService = new PrescriptionService();
  const prescriptionIdFromStorage = prescriptionService.get();

  let prescriptionProfileId =
    params && params.prescriptionProfileId
      ? params.prescriptionProfileId
      : prescriptionIdFromStorage;
  const api = new APIService();
  const helpers = new Helpers();
  const translationService = new TranslationService();
  const [errors, setErrors] = useState<any>([]);
  const [currentPrescriptionProfile, setCurrentPrescriptionProfile] =
    useState<any>({});
  const [load, setLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameStep, setNameStep] = useState<boolean>(false);
  const imgLoadAndCrop: any = useRef();
  const [cropping, setCropping] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<any>(null);
  const [clinicId, setClinicId] = useState<any>(null);
  const [signatureImage, setSignatureImage] = useState<any>(null);
  const [address, setAddress] = useState<string>("");
  const [stamp, setStamp] = useState<any>();
  const [stampErrorFlag, setStampErrorFlag] = useState<boolean>(false);
  const [stampRequiredError, setStampRequiredError] = useState<boolean>(false);
  const [stampEditorEnabled, setStampEditorEnabled] = useState<boolean>(false);
  const [defaultStampPreview, setDefaultStampPreview] = useState();
  const [translations, setTranslations] = useState<any>(null);
  const [showStep, setShowStep] = useState<boolean>(false);
  const [clinicData, setClinicData] = useState<any>({});
  const [medicalLicense, setMedicalLicense] = useState<any>(null);
  const [idPrescriptionProfile, setIdPrescriptionProfile] = useState<any>(null);
  const [overlay, setOverlay] = useState<boolean>(false);

  const { theme }: any = useTheme();
  const stampRef = React.createRef();

  // const getStampPreview = ({ medic, prescriptionProfile, text = false }) => {
  //   const prefix =
  //     medic.professional_prefix && medic.professional_prefix.name
  //       ? `${medic.professional_prefix.name} `
  //       : "";
  //   const name = medic.name.split(" ")[0];
  //   const lastname = medic.lastname.split(" ")[0];
  //   return text ? (
  //     `${prefix}${name} ${lastname}\n${prescriptionProfile.specialties?.join(
  //       ", "
  //     )}\n${
  //       (prescriptionProfile.medical_licenses &&
  //       prescriptionProfile.medical_licenses.medical_licenses_type
  //         ? prescriptionProfile.medical_licenses.medical_licenses_type?.code
  //         : prescriptionProfile.additional_fields.inputs.find(
  //             ({ name }) => name === "professional_registration_type"
  //           )?.value) || "Matrícula"
  //     } ${
  //       (prescriptionProfile.medical_licenses
  //         ? prescriptionProfile.medical_licenses.identification
  //         : prescriptionProfile.additional_fields.inputs.find(
  //             ({ name }) => name === "professional_registration_number"
  //           )?.value) || "NUMERO"
  //     }`
  //   ) : (
  //     <>
  //       {prefix}
  //       {name} {lastname}
  //       <br />
  //       {prescriptionProfile.specialties?.join(", ")}
  //       <br />
  //       {(prescriptionProfile.medical_licenses &&
  //       prescriptionProfile.medical_licenses.medical_licenses_type
  //         ? prescriptionProfile.medical_licenses.medical_licenses_type?.code
  //         : prescriptionProfile.additional_fields.inputs.find(
  //             ({ name }) => name === "professional_registration_type"
  //           )?.value) || "Matrícula"}{" "}
  //       {(prescriptionProfile.medical_licenses
  //         ? prescriptionProfile.medical_licenses.identification
  //         : prescriptionProfile.additional_fields.inputs.find(
  //             ({ name }) => name === "professional_registration_number"
  //           )?.value) || "NUMERO"}
  //     </>
  //   );
  // };

  const getStampPreviewNew = ({
    medic,
    prescriptionProfile,
    text = false,
  }: {
    medic: any;
    prescriptionProfile: any;
    text?: boolean;
  }) => {
    const prefix =
      medic.professional_prefix && medic.professional_prefix.name
        ? `${medic.professional_prefix.name} `
        : "";
    const name = medic.name.split(" ")[0];
    const lastname = medic.lastname.split(" ")[0];
    const specialities =
      prescriptionProfile.specialities &&
      prescriptionProfile.specialities !== null &&
      prescriptionProfile.specialities !== "" &&
      prescriptionProfile.specialities !== "[]"
        ? JSON.parse(prescriptionProfile.specialities)
        : [];

    const specialitiesStamp = specialities.length
      ? specialities
      : [prescriptionProfile.profession];

    return text ? (
      `${prefix}${name} ${lastname}\n${specialitiesStamp?.join(", ")}\n${
        prescriptionProfile.medical_licenses_type.description || "Matrícula"
      } ${
        prescriptionProfile.medical_licenses_jurisdiction.description ==
        "Nacional"
          ? ""
          : prescriptionProfile.medical_licenses_jurisdiction.description ||
            "Matrícula"
      }`
    ) : (
      <>
        {prefix}
        {name} {lastname}
        <br />
        {specialitiesStamp?.join(", ")}
        <br />
        {prescriptionProfile.medical_licenses_type.description ||
          "Matrícula"}{" "}
        {prescriptionProfile.identification || "NUMERO"}
      </>
    );
  };

  const validateCustomStamp = ({ stamp }: any) => {
    let wordLengthErrors = [];
    let lineLengthError = false;
    if (stamp) {
      const divStamp = stamp?.split(/\n/);
      for (let index = 0; index < divStamp.length; index++) {
        const line = divStamp[index];
        if (line.length > CUSTOM_STAMP_MAX_WORD_LENGTH) {
          wordLengthErrors.push(index + 1);
        }
      }
      lineLengthError = stamp?.split(/\n/).length > 3;
    }
    setStampErrorFlag(wordLengthErrors.length > 0 || lineLengthError);
    return {
      wordLengthErrors,
      lineLengthError,
    };
  };

  const setStampToDefaultText = useCallback(() => {
    const stamp = getStampPreviewNew({
      medic: userData,
      prescriptionProfile: currentPrescriptionProfile.medical_licenses,
      text: true,
    });
    validateCustomStamp({ stamp });
    return userData && currentPrescriptionProfile && setStamp(stamp);
  }, [currentPrescriptionProfile, userData, setStamp]);

  const onSubmit = () => {
    sessionStorage.removeItem(userTemporalDataPostRegister);
    const errors = validateEmptyFields({
      name,
    });

    if (errors.length) {
      setErrors(errors);
      showModalInfo(locales_es.infoModal.title, locales_es.infoModal.checkData);
    } else {
      setLoading(true);
      const objData: any = {
        medical_license_id: medicalLicenseId,
        name,
        address,
        stamp: stampEditorEnabled ? stamp : defaultStampPreview,
        personalized_stamp: stampEditorEnabled ? 1 : 0,
        optional_fields: [], // NO SUPPORT FOR NOW
        logo_image: logoImage,
        signature_image: signatureImage,
      };

      if (prescriptionProfileId) {
        objData.active_charger_account =
          currentPrescriptionProfile.active_charger_account;
        api
          .putPrescriptionProfile(prescriptionProfileId, objData)
          .then(() => {
            sendEvent();
            setLoading(false);
            goToNextStep();
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err)
            );
            setLoading(false);
          });
      } else {
        api
          .postPrescriptionProfile(objData)
          .then((res: any) => {
            setLoading(false);
            prescriptionProfileId = res.data.id;
            goToNextStep();
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err)
            );
            setLoading(false);
          });
      }
    }
  };

  const goToNextStep = () => {
    if (
      ((clinicData && clinicData.paylink_enabled) || !clinicData) &&
      !firstProfile
    ) {
      history.push(`/${Routes.PaymentInformation}`, {
        hasTwoSteps: false,
        hasSignup: false,
        stepTitle: "Ultimo Paso",
        stepNumber: 3,
        prescriptionProfileId,
      });
    } else {
      const emails = [
        {
          value: userData && userData.email ? userData.email : "",
        },
      ];
      history.push(`/${Routes.CreatingYourFirstPrescription}`, {
        emails,
        prescriptionProfileId,
      });
    }
  };

  const confirmRemoveLogoImage = () => {
    setLoading(true);
    deleteLogoImage(prescriptionProfileId)
      .then(() => {
        sendEvent();
        setLoading(false);
        setLogoImage(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onRemoveLogoImage = () => {
    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: locales_es.removeImageModal.title,
      message: locales_es.removeImageModal.subtitle,
      actions: [
        {
          text: locales_es.cancel,
          onPress: () => console.log("Cancel Pressed"),
          white: true,
        },
        {
          text: locales_es.removeImageModal.primaryAction,
          onPress: () => {
            confirmRemoveLogoImage();
          },
          white: true,
          colors: [Colors.Tomato, Colors.Red],
        },
      ],
    });
  };

  const confirmRemoveSignatureImage = () => {
    setLoading(true);
    deleteSignatureImage(prescriptionProfileId)
      .then(() => {
        sendEvent();
        setLoading(false);
        setSignatureImage(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onRemoveSignatureImage = () => {
    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: locales_es.removeSignatureModal.title,
      message: locales_es.removeSignatureModal.subtitle,
      actions: [
        {
          text: locales_es.cancel,
          onPress: () => console.log("Cancel Pressed"),
          white: true,
        },
        {
          text: locales_es.deletePrescriptionProfile.primaryAction,
          onPress: () => {
            confirmRemoveSignatureImage();
          },
          white: true,
          colors: [Colors.Tomato, Colors.Red],
        },
      ],
    });
  };

  const sendEvent = () => {
    EventRegister.emit(EVENT_RELOAD_PRESCRIPTION_PROFILES);
  };

  useEffect(() => {
    translationService.translate().then((translation) => {
      setTranslations(translation);
    });
    if (prescriptionProfileId) {
      api
        .getPrescriptionProfile(prescriptionProfileId)
        .then((res: any) => {
          // setPrescriptionProfileId(res.data[0].id);
          setClinicId(res.data.clinic_id);
          setClinicData(res.data.clinic);
          setShowStep(true);
          setName(res.data.name);
          setAddress(res.data.address);
          setCurrentPrescriptionProfile(res.data);
          setStamp(res.data.stamp);
          validateCustomStamp({ stamp: res.data.stamp });
          const stampPreview = getStampPreviewNew({
            medic: userData,
            prescriptionProfile: res.data.medical_licenses,
            text: true,
          });
          setDefaultStampPreview(stampPreview);

          setStampEditorEnabled(res.data.personalized_stamp);

          /* setStamp(res.data.stamp.split(/\r?\n/)); */

          setLogoImage(
            res.data.full_logo_image ? { uri: res.data.full_logo_image } : false
          );
          setSignatureImage(
            res.data.full_signature_image
              ? { uri: res.data.full_signature_image }
              : false
          );
        })
        .catch((err: any) => {
          helpers.processError(err);
        });
    } else {
      api
        .getMedicalLicense(medicalLicenseId)
        .then((res: any) => {
          setMedicalLicense(res.data);
          setName("Principal");
          setShowStep(true);
          setLogoImage(false);
          setSignatureImage(false);
          setShowStep(true);
          setClinicData({ paylink_enabled: true });

          const stampPreview = getStampPreviewNew({
            medic: userData,
            prescriptionProfile: res.data,
            text: true,
          });
          setDefaultStampPreview(stampPreview);
        })
        .catch((err: any) => {
          showModalInfo(locales_es.errorModal.title, helpers.getErrorMsg(err));
        });
    }
  }, [load]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_LOAD_SIGNATURE,
      (base64: any) => {
        if (prescriptionProfileId) {
          setLoad(!load);
        } else {
          setSignatureImage(base64);
        }
      }
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  const openCrop = () => {
    imgLoadAndCrop.current.selectImageFile();
  };

  const uploadImage = (image: any) => {
    if (prescriptionProfileId) {
      setLoading(true);
      setLoadingText(locales_es.uploadingImage);
      uploadLogoImage(prescriptionProfileId, image)
        .then(() => {
          setLoading(false);
          setLoadingText("");
          setLogoImage(image);
          setCropping(false);
        })
        .catch(() => {
          setLoading(false);
          setLoadingText("");
          setCropping(false);
        });
    } else {
      setLogoImage(image);
      setCropping(false);
    }
  };

  const {
    visible: visibleMenuOption,
    open: openMenuOption,
    close: closeMenuOption,
  } = useModalWithKeyboard(false);

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item: any) => item !== inputStr));
  };

  const goToAddSignature = () => {
    // history.push(`/${Routes.AddSignature}`, {prescriptionProfileId});
    setOverlay(
      <AddSignature
        showMainModal={(
          title,
          content,
          actions,
          customStyles,
          contentCustomClassName
        ) =>
          props.showModal(
            title,
            content,
            actions,
            customStyles,
            contentCustomClassName
          )
        }
        hideMainLoading={props.hideMainLoading}
        onFinish={() => {
          setOverlay(null);
        }}
        prescriptionProfileId={prescriptionProfileId}
      />
    );
  };

  const onSend = useCallback(() => {
    const dataRequired: any = {
      address,
    };
    if (stampEditorEnabled) {
      dataRequired.stamp = stamp;
    }
    const errors = validateEmptyFields(dataRequired);
    if (errors.includes("stamp")) {
      setStampRequiredError(true);
    }
    let stampErrors: any = {};

    stampErrors = validateCustomStamp({
      stamp: stampEditorEnabled ? stamp : defaultStampPreview,
    });

    if (
      errors.length ||
      stampErrors.wordLengthErrors.length ||
      stampErrors.lineLengthError
    ) {
      setErrors(
        stampErrors.wordLengthErrors?.length ? [...errors, "stamp"] : errors
      );
      showModalInfo(
        locales_es.infoModal.title,
        [
          locales_es.infoModal.checkData,
          stampErrors.wordLengthErrors
            ? stampErrors.wordLengthErrors.map((w: any) =>
                locales_es.customStampWordLength({
                  w,
                  CUSTOM_STAMP_MAX_WORD_LENGTH,
                })
              )
            : undefined,
          stampErrors.lineLengthError
            ? locales_es.stampMaxLineLength
            : undefined,
        ]
          .filter((w) => !!w)
          .join(". ")
      );
    } else {
      console.log("no errors", { clinicData });
      if (clinicData && !clinicData.paylink_enabled) {
        setNameStep(true);
      } else if (firstProfile) {
        setNameStep(true);
      } else {
        onSubmit();
      }
    }
  }, [address, stamp, stampEditorEnabled, clinicData]);

  const renderWarningSign = () => {
    return (
      <Text size={13} lineHeight={20} marginTop={10} marginLeft={5}>
        <Image
          source={SOURCE_ICON.warning}
          style={{ height: 14, width: 14 }}
          resizeMode="contain"
        />{" "}
        {locales_es.nonEditableProfileField}.
      </Text>
    );
  };

  const showWarningInfo = () => {
    showModalInfo(
      locales_es.infoModal.title,
      locales_es.nonEditableProfileFieldInfo
    );
  };

  return {
    showWarningInfo,
    onSend,
    onBlur,
    openMenuOption,
    uploadImage,
    openCrop,
    onRemoveSignatureImage,
    confirmRemoveSignatureImage,
    onRemoveLogoImage,
    confirmRemoveLogoImage,
    goToNextStep,
    onSubmit,
    stampRef,
    theme,
    translations,
    loading,
    loadingText,
    nameStep,
    cropping,
    setCropping,
    logoImage,
    clinicId,
    signatureImage,
    imgLoadAndCrop,
    address,
    setAddress,
    setNameStep,
    setName,
    setStamp,
    stamp,
    errors,
    name,
    prescriptionProfileId,
    setStampToDefaultText,
    setStampEditorEnabled,
    stampErrorFlag,
    stampRequiredError,
    stampEditorEnabled,
    defaultStampPreview,
    goToAddSignature,
    renderWarningSign,
    overlay,
    setStampRequiredError,
    validateCustomStamp,
    showStep,
    clinicData,
    stepTitle,
    firstProfile,
  };
};

export default useOtherInformationPage;
