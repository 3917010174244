import React, {useEffect, useRef, useState} from 'react';
import {
  View,
  StyleSheet,
  Image,
  TouchableWithoutFeedback,
  Animated,
  TouchableOpacity,
} from 'react-native';
import Text from '../Text';
import {Colors} from '../../configs';
import Theme from '../../style/Theme';
import scale from '../../utils/scale';
import Line from '../Layout/Line';
import Loading from '../Loading';
import Collapsible from 'react-collapsible';
import iconArrowDown from '../../images/ic_arr_down.png';
import iconArrowUp from '../../images/ic_arr_down.png';
import iconTrash from '../../images/ic_trash.png';
import iconEdit from '../../images/ic_edit.png';
import ic_checkbox_active from '../../images/ic_checkbox_active.png';

interface Props {
  medical_insurance: {
    medical_insurance_id?: number;
    medical_insurance_plan: string;
    medical_insurance_name: string;
    medical_insurance_number: string;
    medical_insurance_selected: any;
    medical_insurance_dni_titular: string;
  };
  onPressEditMedicalInsurance: (Id: any) => void;
  onPressBtnDelete: (Id: any) => void;
  onMedicalInsuranceSelect: () => void;
  checked?: boolean;
  edit?: boolean;
}

const MedicalInsuranceItem = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const animatedIconRotation = useRef(new Animated.Value(0)).current;



  return (
    <View>
      {loading && <Loading />}
      <View>
        <TouchableWithoutFeedback
          onPress={() => {
            props.onMedicalInsuranceSelect
              ? props.onMedicalInsuranceSelect()
              : setIsCollapsed(!isCollapsed);
          }} >
          <View
            style={{
              flex: 1,
              marginHorizontal: 24,
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}>
            <View
              style={{
                flex: 0.7,
                flexDirection: 'row',
                alignItems: 'flex-end',
                paddingHorizontal: 24,
                paddingVertical: 5,
              }}>
                {
                props.onMedicalInsuranceSelect ?  (
                  <View style={{marginRight: 10}}>
                    {
                    props.checked && props.medical_insurance.medical_insurance_selected === 1 && (
                      <Image
                        source={ic_checkbox_active}
                        style={{minHeight: 25, minWidth: 25}}
                        resizeMode="contain"
                      />
                    )
                  }
                  {
                    !props.checked && props.medical_insurance.medical_insurance_selected === 1 && (
                      <Image
                        source={ic_checkbox_active}
                        style={{minHeight: 25, minWidth: 25}}
                        resizeMode="contain"
                      />
                    )
                  }

                  {
                    !props.checked && props.medical_insurance.medical_insurance_selected === 0 && (
                      <Image
                        source={ic_checkbox_active}
                        resizeMode="contain"
                        style={{tintColor: Colors.GrayBlue,minHeight: 25, minWidth: 25}}
                      />
                    )
                  }
                  </View>
                ) : null
              }
              <Text marginTop={24} bold size={18}>
                {props.medical_insurance.medical_insurance_name}
              </Text>
            </View>
            <View
              style={{
                flex: 0.3,
                alignItems: 'space-between',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                maxHeight: 20,
              }}>
              {!props.onMedicalInsuranceSelect ? (
                <>
                  <TouchableOpacity
                    style={styles.profileListIcon}
                    onPress={props.onPressEditMedicalInsurance}>
                    <Image
                      source={iconEdit}
                      style={{
                        tintColor: Colors.TealBlue,
                        minHeight: 18, minWidth: 18,
                      }}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                  {
                    props.medical_insurance.medical_insurance_id !== 1 && (

                    <TouchableOpacity
                      style={styles.profileListIcon}
                      onPress={props.onPressBtnDelete}>
                      <Image
                        source={iconTrash}
                        style={{
                          tintColor: Colors.Tomato,
                          minHeight: 25, minWidth: 25,
                        }}
                        resizeMode="contain"
                      />
                    </TouchableOpacity>
                    )
                  }
                </>
              ) : null}
              <TouchableWithoutFeedback
                style={styles.profileListIcon}
                onPress={() => {
                  setIsCollapsed(!isCollapsed);
                }}>
                <Animated.View
                  style={{
                    transform: [
                      {
                        rotate: animatedIconRotation.interpolate({
                          inputRange: [0, 360],
                          outputRange: ['0deg', '-360deg'],
                        }),
                      },
                    ],
                  }}>
                  <Image
                    source={  isCollapsed ? iconArrowDown : iconArrowUp }
                    style={{
                      tintColor: isCollapsed
                        ? Colors.MediumTurquoise
                        : Colors.Platinum,
                        minHeight: "25px", minWidth: "25px",
                    }}
                    resizeMode="contain"
                  />
                </Animated.View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        </TouchableWithoutFeedback>
       <Collapsible trigger open={!isCollapsed} >
          {props.medical_insurance && !props.medical_insurance.medical_insurance_name.toLowerCase().includes('ioscor') && !props.medical_insurance.medical_insurance_name.toLowerCase().includes('mpn - medicamentos de primer nivel') && <View>
            <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
              Plan Médico
            </Text>
            <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
              {props.medical_insurance.medical_insurance_plan}
            </Text>
          </View>}
          {props.medical_insurance && !props.medical_insurance.medical_insurance_name.toLowerCase().includes('mpn - medicamentos de primer nivel') &&
          <View>
            <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
              N° de Afiliado
            </Text>
            <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
              {props.medical_insurance.medical_insurance_number}
            </Text>
          </View>}
          {props.medical_insurance && props.medical_insurance.medical_insurance_name.toLowerCase().includes('ioscor') && !props.medical_insurance.medical_insurance_name.toLowerCase().includes('mpn - medicamentos de primer nivel') && <View>
            <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
              DNI del Titular
            </Text>
            <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
              {props.medical_insurance.medical_insurance_dni_titular}
            </Text>
          </View>}
        </Collapsible>
      </View>
      <Line height={1} marginTop={15} marginBottom={15} />
    </View>
  );
};

export default MedicalInsuranceItem;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  box: {
    marginTop: 16,
    borderRadius: 16,
    paddingBottom: 24,
    marginHorizontal: 16,
    overflow: 'hidden',
  },
  button: {
    borderWidth: 0,
    marginVertical: 10,
  },
  topBox: {
    ...Theme.flexRowSpace,
    paddingTop: 16,
    paddingHorizontal: 24,
  },
  rounded: {
    width: 32,
    height: 32,
    borderRadius: 8,
    backgroundColor: Colors.OysterBay,
    ...Theme.center,
  },
  buttonEdit: {
    width: 24,
    height: 24,
    backgroundColor: Colors.DodgerBlue,
  },
  buttonUpdate: {
    marginHorizontal: 24,
    marginVertical: 12,
  },
  images: {
    width: scale(348),
    height: scale(200),
  },
  iconPhone: {
    backgroundColor: Colors.RedNeonFuchsia,
    width: 24,
    height: 24,
    borderRadius: 8,
  },
  flexRow: {
    ...Theme.flexRow,
    marginTop: 16,
    marginLeft: 24,
  },
  contentIcon: {
    width: 40,
    height: 40,
    backgroundColor: Colors.TealBlue,
    ...Theme.center,
    borderRadius: 16,
    marginRight: 16,
  },
  setRow: {
    ...Theme.flexRow,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  viewLanguage: {
    ...Theme.flexRow,
    marginLeft: 24,
    marginTop: 8,
  },
  schoolPhoto: {
    width: '100%',
    height: 56,
    maxHeight: 56,
    // borderRadius: 56,
    marginRight: 8,
    alignSelf: 'flex-start',
  },
  viewEducation: {
    ...Theme.flexRow,
    marginLeft: 24,
    marginTop: 8,
  },
  itemAward: {
    marginTop: 24,
    marginLeft: 24,
  },
  profileListIcon: {
    marginHorizontal: 5,
  },
});
